import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Splash from '../components/Splash/Splash'

export default function PrivacyPolicy() {
  return <Layout>
    <SEO title={'Credits'}></SEO>
    <Splash></Splash>
    <h2>Privacy Policy</h2>
    <p>At CanITeachMyselfKiteboarding.com, we are committed to protecting your personal information and privacy. Please contact us at info@caniteachmyselfkiteboarding.com if you have any questions regarding privacy or data retention. CanITeachMyselfKiteboarding.com is owned by <a href="https://www.ingraco.com">Ingraco</a>. This document outlines what data we collect, and how it is used.</p>

    <h3>What information is collected?</h3>
    <p>As a visitor to our site, we use your access information for analytical purposes which is aggregated to provide global insights into usage of the application. This includes approximate geographic locations, browser and device characteristics and IP addresses.</p>
    <p>When you contact us by email, we will store your emails according to our data retention policy.</p>
    <p>Users who sign in to our site agree for additional information to be stored and processed. We store users email addresses and password hashes.</p>

    <h3>How do we use the information?</h3>
    <p>We do not sell or pass on your information to third parties. If this changes in the future, we will always ask your permission first and request that you 'opt in'. Currently we have no intention of passing your data on. The data we collect is used to what countries our users are based amd the types of devices used to access the site to improve the service we offer. We also analyse the traffic through our site to help us understand the features that are being used the most, for example the time spent on individual pages.</p>
    <p>To allow us to identify users with accounts, we also store email addresses and password hashes. Authentication is handled by <a href="https://firebase.google.com/docs/auth">Firebase, a Google product</a> so we don't directly store your log in credentials, although the email address are visible to us. We also use your email address to identify any schools you manage. This allows us to only provide edit access to your own schools and prevent other people from making changes to your school details.</p>
    <p>We may send you an email from time to time regarding important account information (for example, changes to this privacy policy). We will not send you marketing information without your consent.</p>

    <h3>How do we use cookies?</h3>
    <p>We use cookies to help identify you. Cookies enable us to keep an active session when you log into the application.</p>

    <h3>How long do we store your information?</h3>
    <p>Email addresses are stored as long as a user has an active account. If you wish to delete your account, you can do so from your account dashboard. On deleting your account, we will immediately delete your email address from our authentication provider. This means that deleting your account is irreversible.</p>
    <p>When you email us, we will store the email and our responses to you for up to 7 years unless you request the emails be deleted sooner.</p>
    <p>Our servers are hosted in the United States of America as a service provided by Google Cloud Platform. We do not have physical access to the data centre, and do not provide any third party with access credentials.</p>

    <h3>How can you ask more questions?</h3>
    <p>You can email us directly at info@caniteachmyselfkiteboarding.com if you have any additional questions.</p>
  </Layout>
}